import { Divider, Flex } from "@jewlr/storybook/core"
import React from "react"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"

import useCollection from "areas/collection/hoc/use-collection"
import { trackSuperFiltersEvent } from "helpers/collection"
import { hideScrollbar } from "styles/utils"

const Container = styled(Flex)`
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  ${hideScrollbar}
  ${(props) => props.theme.mediaQueries.desktop`
    flex-wrap: wrap;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: auto;
  `}
`

export const FilterLink = styled(Link)`
  align-items: center;
  border: solid 1px ${(props) => props.theme.colors.grey[20]};
  border-radius: 4px;
  display: inline-flex;
  font-size: 16px;
  font-weight: 700;
  height: 38px;
  line-height: 25px;
  margin-right: 6px;
  padding: 0 20px;
  &:focus-visible {
    outline-offset: -2px;
  }
  &:last-child {
    margin-right: 0;
  }
  ${(props) => props.theme.mediaQueries.desktop`
    font-size: 18px;
    height: 40px;
    margin-bottom: 10px;
    margin-right: 10px;
  `}
`

const SuperFilters = () => {
  const location = useLocation()
  const { data: { super_filters: superFilters } = {} } = useCollection()

  if (!superFilters) return null

  return (
    <>
      <Container aria-label="Quick Filters" as="nav">
        {superFilters.links.map((filter, index) => (
          <FilterLink
            data-lc="super-filters"
            key={`${index}super_filter${filter.url}`}
            onClick={() => {
              if (superFilters.type === "links") {
                trackSuperFiltersEvent(location.pathname, filter.url)
              }
            }}
            to={filter.url}
          >
            {filter.name}
          </FilterLink>
        ))}
      </Container>
      <Divider
        bg="grey.10"
        display={{ _: "none", desktop: "block" }}
        mb={1}
        mt={0}
      />
    </>
  )
}

export default SuperFilters
