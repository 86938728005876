import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import GenericNotFound from "app/generic-not-found"
import Homepage from "areas/homepage/jewlr"
import Landing from "areas/landing"

import fetchCollection from "./hoc/fetch-collection"
import useCollection from "./hoc/use-collection"
import FullLoading from "./loaders"

import Collection from "./"

const LoadCollection = ({ staticContext }) => {
  const { data, disabled, error } = useCollection()
  const { model } = data || {}
  const loading = useSelector((state) => state.collection.loading)

  if (error || disabled) {
    return <GenericNotFound staticContext={staticContext} />
  } else if (!data || loading === "full") {
    return <FullLoading />
  } else if (model === "landing") {
    return data.url_path === "homepage" ? <Homepage /> : <Landing />
  }

  return <Collection />
}

LoadCollection.propTypes = {
  staticContext: PropTypes.object,
}

export default fetchCollection(LoadCollection)
