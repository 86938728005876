import { Button, Typography } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import useCollection from "areas/collection/hoc/use-collection"
import { updateNewPageStartIndex } from "areas/collection/store/actions"

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  padding: 8px 20px;
`

const StyledButton = styled(Button).attrs((props) => ({
  bg: "primary",
  color: "white",
  fontSize: "18px",
  forwardedAs: Link,
  height: "48px",
  lineHeight: "18px",
  width: "260px",
  ...props,
}))``

const LoadMoreProducts = ({ newPageFocusRef }) => {
  const ref = useRef()
  const dispatch = useDispatch()
  const newPageStartIndex = useSelector(
    (state) => state.collection.newPageStartIndex
  )

  const { data = {}, fetchNextPage, isFetchingNextPage } = useCollection()
  const currentNumOfProducts = data.product_result?.length || -1
  const {
    current_page_number,
    next_page,
    results_per_page,
    total_product_count,
  } = data.pagination || {}

  useEffect(() => {
    // Maintain focus on the first product of the new page
    // on Load More button click
    if (!isFetchingNextPage && newPageFocusRef.current) {
      newPageFocusRef.current.focus()
    }
  }, [isFetchingNextPage, newPageStartIndex])

  return (
    <Container ref={ref}>
      <Typography color="grey.65" fontSize="14px" lineHeight="16px">
        Viewing {current_page_number * results_per_page} of{" "}
        {total_product_count} Products
      </Typography>
      <StyledButton
        data-cy="collection-load-product-button"
        disabled={isFetchingNextPage}
        loading={isFetchingNextPage}
        onClick={(e) => {
          e.preventDefault()
          dispatch(updateNewPageStartIndex(currentNumOfProducts))
          fetchNextPage()
        }}
        replace
        to={`/${next_page}`}
      >
        {isFetchingNextPage ? "Loading" : "View More"}
      </StyledButton>
    </Container>
  )
}

LoadMoreProducts.propTypes = {
  newPageFocusRef: PropTypes.object,
}

export default LoadMoreProducts
