import { useEffect, useLayoutEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import { toggleScrollTrack } from "app-store-v2/app/actions"

export const useScrollRestoration = () => {
  const { action } = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const scrollMap = useSelector((state) => state.scrollMap.data)

  useEffect(() => {
    dispatch(toggleScrollTrack(true))

    return () => {
      dispatch(toggleScrollTrack(false))
    }
  }, [dispatch])

  useLayoutEffect(() => {
    if (action === "POP") {
      const scrollPos = scrollMap[pathname]
      if (scrollPos) {
        window.scrollTo(0, scrollPos)
      }
    }
  }, [action, pathname, scrollMap])
}

export default useScrollRestoration
