import { faAngleDown as lightAngleDown } from "@jewlr/pro-light-svg-icons/faAngleDown"
import { faAngleUp as lightAngleUp } from "@jewlr/pro-light-svg-icons/faAngleUp"
import { Box, Button, FontAwesomeIcon } from "@jewlr/storybook/core"
import { useHandleOutsideClick } from "@jewlr/storybook/helpers"
import loadable from "@loadable/component"
import { usePress } from "@react-aria/interactions"
import PropTypes from "prop-types"
import React, { Suspense, useRef } from "react"
import styled from "styled-components"

import withFilters from "areas/collection/hoc/with-filters"
import { retry } from "helpers/application"

const SortFilter = loadable(() => retry(() => import("./sort-filter")), {
  ssr: false,
})

const FilterToggle = styled(Button).attrs((props) => ({
  color: "black",
  fontSize: "16px",
  lineHeight: "25px",
  variant: "text",
  ...props,
}))`
  display: block;
`

const FilterArrow = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.black};
  margin-left: 5px;
  vertical-align: text-bottom;
`

const CurrentSort = styled(Box).attrs((props) => ({
  as: "strong",
  ml: "4px",
  ...props,
}))``

const InternalSortMenu = ({
  currentSortOption,
  loading,
  openedSort,
  toggleSort,
}) => {
  const sortMenuRef = useRef()
  const { pressProps } = usePress({
    isDisabled: !!loading,
    onPress: () => toggleSort(!openedSort),
  })

  useHandleOutsideClick({
    enabled: openedSort,
    onOutsideClick: () => toggleSort(false),
    ref: sortMenuRef,
  })

  return (
    <Box aria-label="Sort by" as="nav" position="relative" ref={sortMenuRef}>
      <FilterToggle
        aria-expanded={openedSort}
        aria-haspopup="listbox"
        data-cy="collection-sort-button"
        id="collection-sort-button"
        {...pressProps}
      >
        Sort by:{" "}
        {currentSortOption && (
          <CurrentSort>{currentSortOption.label}</CurrentSort>
        )}
        <FilterArrow icon={openedSort ? lightAngleUp : lightAngleDown} />
      </FilterToggle>
      {typeof window !== "undefined" && openedSort && (
        <Suspense fallback={<div />}>
          <SortFilter />
        </Suspense>
      )}
    </Box>
  )
}

InternalSortMenu.propTypes = {
  currentSortOption: PropTypes.object,
  loading: PropTypes.string,
  openedSort: PropTypes.bool,
  sortMenu: PropTypes.object,
  toggleSort: PropTypes.func,
}

const SortMenu = withFilters(InternalSortMenu)

export default SortMenu
