import { Box, Flex, Typography } from "@jewlr/storybook/core"
import React from "react"
import styled from "styled-components"

import useCollection from "areas/collection/hoc/use-collection"
import { IS_JEWLR } from "helpers/application"

import Breadcrumb from "./breadcrumb"
import Description from "./description"
import Title from "./title"

const Container = styled(Box).attrs((props) => ({
  mb: "14px",
  mt: 2,
  pt: { desktop: 2 },
  ...props,
}))``

const ProductCount = styled(Typography).attrs({
  color: "#757575",
})`
  font-size: 14px;
`

const Heading = () => {
  const {
    data: { pagination: { total_product_count } = {} } = {},
  } = useCollection()

  return (
    <Container>
      <Flex
        alignItems="flex-start"
        justifyContent={IS_JEWLR() ? "space-between" : "flex-end"}
      >
        {IS_JEWLR() && <Breadcrumb />}
        <div aria-atomic aria-live="polite">
          <ProductCount>{total_product_count} Products</ProductCount>
        </div>
      </Flex>
      <Flex alignItems="flex-start" justifyContent="space-between" mt={2}>
        <Box width={{ desktop: "75%" }}>
          <Title />
          <Description />
        </Box>
      </Flex>
    </Container>
  )
}
export default Heading
