import { Typography } from "@jewlr/storybook/core"
import React from "react"

import useCollection from "areas/collection/hoc/use-collection"

const Title = () => {
  const { data: { title } = {} } = useCollection()

  return (
    <Typography as="h1" fontSize="25px" fontWeight="700" mb={1}>
      {title}
    </Typography>
  )
}

export default Title
