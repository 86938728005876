import { Box } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet-async"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"

import useCollection from "areas/collection/hoc/use-collection"
import LandingElement from "areas/landing/landing-element"
import LandingLoading from "areas/landing/loaders"
import IntersectionWrapper from "components/common/intersection-wrapper"
import { openModal } from "components/modal"
import { isTestMode, retry } from "helpers/application"
import { logCurrentPageInCollectionStats } from "helpers/collection"
import useScrollRestoration from "helpers/use-scroll-restoration"
import withLanding from "hoc/landing/with-landing"

const HomepageBannerCarousel = loadable(() =>
  retry(() => import("./homepage-banner-carousel"))
)
const LandingHeroBanner = loadable(() =>
  retry(() => import("areas/landing/elements/hero-banner"))
)

const Homepage = ({
  bannerIds,
  heroBanners,
  // shuffledIds,
}) => {
  const { search } = useLocation()
  const { data, isRefetching } = useCollection()

  const preloadableBannerImages =
    heroBanners &&
    bannerIds?.length > 0 &&
    heroBanners[bannerIds[0]] &&
    (heroBanners[bannerIds[0]].data?.items || []).map((item) => item.image)

  useScrollRestoration()

  useEffect(() => {
    logCurrentPageInCollectionStats("homepage")

    if (!isTestMode) {
      submit_ga_event_click("Page", "Viewed Homepage")
      dataLayer.push({
        event: "viewed-homepage",
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const signin = queryString.parse(search).signin === "true"

      if (signin) {
        const user_obj = window.localStorage.getItem("user_obj")
        const loggedIn =
          user_obj && Object.keys(JSON.parse(user_obj)).length > 0

        if (!loggedIn) {
          openModal("#authenticate")
        }
      }
    }
  }, [])

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        {/* eslint-disable sort-keys, react/jsx-sort-props, react/no-unknown-property */}
        {preloadableBannerImages.map((image, i) =>
          (image.srcSet || []).map((srcset) => (
            <link
              key={`homepage-banner-preload-${image.id || i}-${srcset.media}`}
              rel="preload"
              fetchPriority="high"
              as="image"
              imageSrcSet={srcset.srcSet}
              imageSizes={srcset.sizes}
              media={srcset.media}
            />
          ))
        )}
        {(data.preloadable_images || []).map((image, i) =>
          (image.srcSet || []).map((srcset) => (
            <link
              key={`homepage-image-preload-${image.id || i}-${srcset.media}`}
              rel="preload"
              fetchPriority="high"
              as="image"
              imageSrcSet={srcset.srcSet}
              imageSizes={srcset.sizes}
              media={srcset.media}
            />
          ))
        )}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            url: `https://${sl.config.site_url}`,
            name: "Jewlr",
            logo: "https://assets.jewlr.com/images/logos/jewlr-logo.svg",
            email: sl.config.customer_service_email,
            contactPoint: [
              {
                "@type": "ContactPoint",
                email: sl.config.customer_service_email,
                telephone: sl.config.customer_service_toll_free,
                contactType: "customer service",
              },
            ],
            sameAs: [
              "https://www.facebook.com/jewlrcom",
              "https://www.instagram.com/jewlr/",
              "https://www.youtube.com/channel/UC11M7JozPazDfSi4dMf_wOg",
              "https://www.pinterest.com/jewlrcom/_shop/",
            ],
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebSite",
            url: `https://${sl.config.site_url}`,
            potentialAction: {
              "@type": "SearchAction",
              target: `https://${sl.config.site_url}/search?k={search_term}`,
              query: "required",
              "query-input": "required name=search_term",
            },
          })}
        </script>
        {/* eslint-enable sort-keys, react/jsx-sort-props, react/no-unknown-property */}
      </Helmet>
      {bannerIds?.length > 1 ? (
        <Box containWide>
          <HomepageBannerCarousel banners={heroBanners} order={bannerIds} />
        </Box>
      ) : bannerIds?.length == 1 ? (
        <LandingHeroBanner
          element={heroBanners && heroBanners[bannerIds[0]]}
          homepage
          lazyload={false}
        />
      ) : null}
      {(data?.elements_list || []).map((el, index) => {
        const elId = el.anchor_name || el.id
        const element = data.elements.find((elm) => elm.id === el.id)
        let content

        if (element) {
          // SSR (Pre-render) the first THREE elements
          if (index <= 2) {
            content = (
              <LandingElement el={element} index={index} wrapTheChild={false} />
            )
          } else {
            // Lazy-loaded element
            content = (
              <IntersectionWrapper>
                <LandingElement
                  el={element}
                  index={index}
                  wrapTheChild={false}
                />
              </IntersectionWrapper>
            )
          }
        }

        return (
          <div id={elId} key={`homepage-wrapper-${index}`}>
            {content}
          </div>
        )
      })}
      {isRefetching && <LandingLoading partial />}
    </>
  )
}

Homepage.propTypes = {
  bannerIds: PropTypes.array,
  heroBanners: PropTypes.object,
  // shuffledIds: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    bannerIds: state.sale.homepage_hero_banner_ids,
    heroBanners: state.sale.homepage_hero_banners,
  }
}

export default connect(mapStateToProps)(withLanding(Homepage))
