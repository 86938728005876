import { Button, Typography } from "@jewlr/storybook/core"
import React, { useState } from "react"

import useCollection from "areas/collection/hoc/use-collection"

const Description = () => {
  const [showDescription, toggleDescription] = useState(false)
  const { data } = useCollection()
  const longSubtitle = data?.long_subtitle
  const subtitle = data?.subtitle

  return (
    <div>
      <Typography fontSize={{ _: "15px", tablet: "18px" }}>
        {showDescription ? `${subtitle} ${longSubtitle}` : subtitle}{" "}
        {!!longSubtitle && (
          <Button
            color="grey.65"
            onClick={() => toggleDescription(!showDescription)}
            underline
            variant="text"
          >
            {showDescription ? "Less" : "More"}
          </Button>
        )}
      </Typography>
    </div>
  )
}

export default Description
