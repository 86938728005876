import React, { useCallback, useEffect } from "react"

import useCollection from "areas/collection/hoc/use-collection"
import fetchWishlist from "hoc/fetch-wishlist"

export default function withLanding(WrappedComponent) {
  const WithLanding = (props) => {
    const { data, isRefetching, refetch } = useCollection()
    const { all_elements_fetched } = data

    const scrollListener = useCallback(() => {
      if (!all_elements_fetched && !isRefetching) {
        refetch()
      }
    }, [all_elements_fetched, isRefetching])

    useEffect(() => {
      // Add a listener if all elements haven't been fetched yet
      window.addEventListener("scroll", scrollListener)
      return () => {
        // Remove the listener
        window.removeEventListener("scroll", scrollListener)
      }
      // Only fire this effect if all_elements_fetched changed
    }, [scrollListener])

    return <WrappedComponent {...props} />
  }

  return fetchWishlist(WithLanding)
}
