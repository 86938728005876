import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import useCollection from "areas/collection/hoc/use-collection"

const StyledBreadcrumb = styled.ol`
  color: ${(props) => props.theme.colors.grey[50]};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    color: #757575;
    display: inline;
  }

  li + li:before {
    content: "/";
    padding: 0 4px;
  }
`

const Breadcrumb = () => {
  const { data } = useCollection()
  const breadcrumbs = data?.breadcrumb || []

  return (
    <nav aria-label="Breadcrumb">
      <StyledBreadcrumb>
        {breadcrumbs.map((breadcrumb) => (
          <li key={`breadcrumb_${breadcrumb.name}`}>
            {breadcrumb.url ? (
              <Link data-lc="breadcrumb" to={breadcrumb.url}>
                {breadcrumb.name}
              </Link>
            ) : (
              <>{breadcrumb.name}</>
            )}
          </li>
        ))}
      </StyledBreadcrumb>
    </nav>
  )
}

export default Breadcrumb
