import { Box } from "@jewlr/storybook/core"
import { Switch } from "@jewlr/storybook/form"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import withFilters from "areas/collection/hoc/with-filters"

const Container = styled(Box).attrs({
  pb: { _: 1, tablet: "10px" },
  pt: { _: "4px", tablet: "10px" },
})``

const DeliveryToggle = styled(Switch).attrs((props) => ({
  containerStyle: {
    justifyContent: "space-between",
    width: "100%",
  },
  defaultBackground: props.theme.colors.black,
  labelPosition: "left",
  labelStyle: {
    color: "greenDarker",
    fontSize: { _: "16px", tablet: "14px" },
    fontWeight: "700",
    letterSpacing: "-0.03em",
    lineHeight: { _: "18px", tablet: "16px" },
  },
  sz: "sm",
  toggleBackground: props.theme.colors.greenDarker,
}))``

const InternalHolidayDeliveryToggle = ({ filter, setFilter }) => {
  const { categories, unique: isUnique } = filter

  if (!categories || categories.length < 1) return null

  return categories.map((category, index) => (
    <Container key={`${category.name}${index}`}>
      <DeliveryToggle
        checked={category.is_active_filter}
        disabled={category.disabled}
        id={category.filter_param[0]}
        label={category.name}
        onChange={() =>
          setFilter(category.filter_param, isUnique, category.belongs_to)
        }
      />
    </Container>
  ))
}

InternalHolidayDeliveryToggle.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
}

const HolidayDeliveryToggle = withFilters(InternalHolidayDeliveryToggle)

export default HolidayDeliveryToggle
