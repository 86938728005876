import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { Helmet } from "react-helmet-async"
import { useDispatch } from "react-redux"
import { withRouter } from "react-router"

import {
  clearCollection,
  setCollection,
  setLoading,
} from "areas/collection/store/actions"
import { submit_ga_tracker } from "helpers/analytics-helpers"

import useCollection from "./use-collection"

export default function fetchCollection(WrappedComponent) {
  const InternalFetchCollection = ({
    collectionData,
    history,
    landingData,
    match,
    ssrModel,
    ...props
  }) => {
    const urlPath = match.params.url_path || "homepage"
    const currentUrlPath = useRef(urlPath)
    const dispatch = useDispatch()

    const ssrData =
      typeof window === "undefined"
        ? ssrModel === "collection"
          ? collectionData
          : landingData
        : undefined

    const queryData = useCollection(ssrData)
    const {
      data,
      fetchPreviousPage,
      hasPreviousPage,
      isFetchingPreviousPage,
      isLoading,
      isPreviousData,
    } = queryData
    const { model } = data || {}

    useEffect(() => {
      dispatch(clearCollection())
    }, [urlPath])

    useEffect(() => {
      // When SSR loading a page > 1, we only have the data from the current page.
      // Here, we gradually fetch each previous page back to page 1.
      if (hasPreviousPage && !isFetchingPreviousPage) {
        fetchPreviousPage()
      }
    }, [fetchPreviousPage, isFetchingPreviousPage, hasPreviousPage])

    useEffect(() => {
      if (isLoading || isPreviousData) {
        dispatch(
          setLoading(
            !!model && currentUrlPath.current === urlPath ? "partial" : "full"
          )
        )
      } else {
        dispatch(setLoading(""))
      }
      currentUrlPath.current = urlPath
    }, [isLoading, isPreviousData, urlPath, model])

    useEffect(() => {
      if (data) {
        if (data.redirect_url) {
          if (data.custom_page_view) {
            submit_ga_tracker(data.custom_page_view)
          }

          history.replace(data.redirect_url)
          return
        }

        if (model === "collection") {
          dispatch(setCollection(data))

          if (data.pagination?.current_page) {
            history.replace(`/${data.pagination.current_page}`)
          }
        }
      }
    }, [data])

    let pageUrl = ""
    let nextPageUrl = ""
    let prevPageUrl = ""
    let productItemList = []
    let seoTitle = ""
    let seoDescription = ""
    let breadcrumbList

    if (data) {
      if (model === "collection") {
        pageUrl = data.pagination.current_page
        productItemList = data.product_result.map((product, index) => {
          return {
            "@type": "ListItem",
            position: index,
            url: `https://${sl.config.site_url}/products/${product.style_code}`,
          }
        })
        nextPageUrl = data.pagination.next_page
        prevPageUrl = data.pagination.prev_page
        seoTitle = data.seo_title
        seoDescription = data.seo_description

        breadcrumbList = data.breadcrumb.map((item, i) => ({
          "@type": "ListItem",
          item: {
            "@id": `https://${sl.config.site_url}${item.url || `/${pageUrl}`}`,
            name: item.seo_title,
          },
          position: i + 1,
        }))
      } else if (model === "landing" && data.url_path !== "homepage") {
        pageUrl = data.url_path
        productItemList = data.products.map((product, index) => {
          return {
            "@type": "ListItem",
            position: index,
            url: `https://${sl.config.site_url}/products/${product.style_code}`,
          }
        })
        seoTitle = data.seo_title
        seoDescription = data.seo_description

        breadcrumbList = [
          {
            "@type": "ListItem",
            item: {
              "@id": `https://${sl.config.site_url}`,
              name: "Homepage",
            },
            position: 1,
          },
          {
            "@type": "ListItem",
            item: {
              "@id": `https://${sl.config.site_url}/${pageUrl}`,
              name: seoTitle,
            },
            position: 2,
          },
        ]
      }
    }

    return (
      <>
        {/* eslint-disable sort-keys */}
        <Helmet>
          {urlPath === "search" && <meta content="noindex" name="robots" />}
          {prevPageUrl && (
            <link
              href={`https://${sl.config.site_url}/${prevPageUrl}`}
              rel="prev"
            />
          )}
          {nextPageUrl && (
            <link
              href={`https://${sl.config.site_url}/${nextPageUrl}`}
              rel="next"
            />
          )}
          {seoTitle && (
            <>
              <title>{seoTitle}</title>
              <meta content={seoTitle} property="og:title" />
            </>
          )}
          {seoDescription && (
            <meta content={seoDescription} name="description" />
          )}
          {seoDescription && (
            <meta content={seoDescription} property="og:description" />
          )}
          {pageUrl && (
            <meta
              content={`https://${sl.config.site_url}/${pageUrl}`}
              property="og:url"
            />
          )}
          {(model == "collection" || model == "landing") &&
            productItemList.length > 0 && (
              <script type="application/ld+json">
                {JSON.stringify({
                  "@context": "https://schema.org",
                  "@type": "ItemList",
                  url: `https://${sl.config.site_url}/${pageUrl}`,
                  numberOfItems: productItemList.length,
                  itemListElement: productItemList,
                })}
              </script>
            )}
          {breadcrumbList && (
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "BreadcrumbList",
                itemListElement: breadcrumbList,
              })}
            </script>
          )}
        </Helmet>
        {/* eslint-enable sort-keys */}
        <WrappedComponent
          history={history}
          match={match}
          model={model}
          {...props}
        />
      </>
    )
  }

  InternalFetchCollection.propTypes = {
    collectionData: PropTypes.object,
    history: PropTypes.object,
    landingData: PropTypes.object,
    match: PropTypes.object,
    ssrModel: PropTypes.string,
  }

  const FetchCollection = withRouter(InternalFetchCollection)

  return FetchCollection
}
