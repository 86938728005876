import { Box, Button, Flex, Image } from "@jewlr/storybook/core"
import { Checkbox } from "@jewlr/storybook/form"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"

import withFilters from "areas/collection/hoc/with-filters"

import CategoryIcon from "./category-icon"

const Container = styled(Flex).attrs((props) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  pb: "15px",
  px: { _: "15px", desktop: 0 },
  ...props,
}))`
  width: 100%;
`
const Category = styled(Box).attrs((props) => ({
  fontSize: "18px",
  mb: { _: "25px", desktop: "15px" },
  ...props,
}))`
  width: 100%;
`

const CategoryName = styled.span`
  color: ${(props) => props.theme.colors.black};
  letter-spacing: 0.5px;
`

const ViewMoreButton = styled(Button).attrs({
  fontSize: "14px",
  underline: true,
  variant: "text",
})`
  color: ${(props) => props.theme.colors.black};
`

const SearchBox = styled(Flex).attrs({
  alignItems: "center",
  mb: "10px",
  mr: "15px",
  p: "6px",
})`
  border: 1px solid ${(props) => props.theme.colors.black};
  border-radius: 8px;
  &:focus-within {
    border: 2px solid ${(props) => props.theme.colors.primary};
  }
`

const StyledInput = styled.input`
  border: none;
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 20px;
  outline: none;
  width: 100%;
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.grey[50]};
    font-style: italic;
  }
  &:focus {
    outline: none;
  }
`

const InternalFilterCategories = ({ filter, isMobile, setFilter }) => {
  const {
    categories,
    has_icons: hasIcons,
    unique: isUnique,
    min_display: minDisplay,
    name,
    searchable,
  } = filter

  if (!categories || categories.length < 1) return null

  const [viewMore, setViewMore] = useState(false)
  const [query, setQuery] = useState("")

  const showSearchBox = !isMobile && searchable && categories.length >= 6

  const filteredCategories = categories.filter(
    ({ name }) =>
      !showSearchBox || name.toLowerCase().includes(query.toLowerCase())
  )

  const showViewMore =
    !isMobile &&
    !viewMore &&
    minDisplay &&
    minDisplay < filteredCategories.length

  return (
    <Container aria-label={name} contain role="group">
      {showSearchBox && (
        <SearchBox>
          <Image
            alt="search"
            height="15px"
            mr="5px"
            src={require("images/icons/search.svg")}
            width="15px"
          />
          <StyledInput
            onChange={(e) => setQuery(e.target.value)}
            placeholder={`Search ${name}...`}
          />
        </SearchBox>
      )}
      {filteredCategories
        .slice(0, showViewMore ? minDisplay : filteredCategories.length)
        .map((category, index) => {
          return (
            <Category key={`category${index}`}>
              <Checkbox
                checked={category.is_active_filter}
                disabled={category.disabled}
                onChange={() =>
                  setFilter(
                    category.filter_param,
                    isUnique,
                    category.belongs_to
                  )
                }
                variant="primary"
                width="100%"
              >
                <Flex
                  alignItems="center"
                  data-cy={`collection-filter-category-${category.name}-${
                    isMobile ? "mobile" : "desktop"
                  }`}
                >
                  {hasIcons && <CategoryIcon category={category} />}
                  <CategoryName>{category.name}</CategoryName>
                </Flex>
              </Checkbox>
            </Category>
          )
        })}
      {showViewMore && (
        <ViewMoreButton
          data-cy={`collection-filter-category-${name
            .replace(/\s+/g, "-")
            .toLowerCase()}-view-more`}
          onClick={() => setViewMore(true)}
        >
          View More
        </ViewMoreButton>
      )}
    </Container>
  )
}

InternalFilterCategories.propTypes = {
  filter: PropTypes.object,
  isMobile: PropTypes.bool,
  setFilter: PropTypes.func,
}

const FilterCategories = withFilters(InternalFilterCategories)

export default FilterCategories
