import { createSelector } from "@reduxjs/toolkit"
import { debounce } from "lodash"
import React, { useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"

import useCollection from "areas/collection/hoc/use-collection"
import {
  clearCategoryFilters,
  toggleFilterMenu,
  toggleSortMenu,
  updateFilter,
  updateUniqueFilter,
  updateCurrentSort,
} from "areas/collection/store/actions"

const selectCollectionProps = createSelector(
  [(state) => state.collection],
  (collection) => {
    const {
      loading,
      filters,
      open_filter_menu,
      open_sort_menu,
      sort,
    } = collection
    return {
      currentSort: sort,
      filters,
      hasFilters: filters.some((filter) =>
        filter.categories.some((cat) => cat.is_active_filter)
      ),
      loading,
      openedFilter: open_filter_menu,
      openedSort: open_sort_menu,
      tags: filters.reduce(
        (tags, filter) => [
          ...tags,
          ...filter.categories.filter((cat) => cat.is_active_filter),
        ],
        []
      ),
    }
  }
)

export default function withFilters(WrappedComponent) {
  const WithFilters = (props) => {
    const dispatch = useDispatch()
    const { data } = useCollection()

    const {
      currentSort,
      filters,
      hasFilters,
      loading,
      openedFilter,
      openedSort,
      tags,
    } = useSelector(selectCollectionProps)

    const sortMenu = data?.sort_menu || {}
    const currentSortOption = useMemo(() => {
      const options = data?.sort_menu?.options || []
      return options.find((opt) => opt.sort === currentSort) ?? options?.[0]
    }, [currentSort, data])

    const collectionProps = {
      collectionFilters: filters,
      currentSort,
      currentSortOption,
      disableFilters: data?.filter_menu?.disable_filters,
      disableSort: sortMenu?.disable_sort,
      hasFilters,
      loading,
      openedFilter,
      openedSort,
      sortMenu,
      tags,
    }

    const dispatchProps = {
      clearAllTags: (e) => {
        e.preventDefault()
        dispatch(clearCategoryFilters("all"))
      },
      closeModal: () => {
        dispatch(toggleFilterMenu(false))
      },
      removeFilter: debounce((e, filter) => {
        e.preventDefault()
        dispatch(updateFilter(filter))
      }, 500),
      setFilter: debounce((filter, unique, belongs_to) => {
        if (unique) {
          // deselect all other checkboxes in the category, then check the one requested
          dispatch(updateUniqueFilter({ belongs_to, filter }))
        } else {
          dispatch(updateFilter(filter))
        }
      }, 500),
      toggleFilter: (open) => {
        dispatch(toggleFilterMenu(open))
      },
      toggleSort: (open) => {
        dispatch(toggleSortMenu(open))
      },
      updateSort: (option) => {
        dispatch(
          updateCurrentSort(option !== sortMenu?.default_sort ? option : null)
        )
        dispatch(toggleSortMenu(false))
      },
    }

    return (
      <WrappedComponent {...collectionProps} {...dispatchProps} {...props} />
    )
  }

  return WithFilters
}
